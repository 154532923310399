<div *ngIf="!empresaNova" class="container">
  <div class="container-width">
    <!-- TITULO -->
    <div class="d-flex flex-row align-items-baseline" style="margin-top: -10px;">
      <div class="flex-grow-1">
        <div>
          <h4 style="line-height: 2">Importação e exportação de XMLs</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-3 d-flex column-gap-3" style="margin-left: 20px;">
    <div>
      <p-fieldset legend="Importação">
        <div>
          <p-dropdown
            [style]="{'margin': '0px 5px 5px 0px'}"
            [options]="tipo"
            [(ngModel)]="selectedTipo"
            optionLabel="name"
            [showClear]="selectedTipo != null && selectedTipo.key != 99"
            (onChange)="onChangeTipo()"
            placeholder="Selecione o Tipo">
          </p-dropdown>
          <p-dropdown
            [autoDisplayFirst]="true"
            [options]="municipiosContratados"
            [editable]="false"
            [(ngModel)]="selectedMunicipio"
            [ngModelOptions]="{standalone: true}"
            optionLabel="municipio"
            placeholder="Selecione o município"
            [ngClass]="selectedTipo && selectedTipo.key === 3 ? '' : 'd-none'">
          </p-dropdown>
          <p-fileUpload
            #fileUpload
            [multiple]="true"
            accept=".xml"
            [customUpload]="true"
            (uploadHandler)="onUploadXml($event)"
            chooseLabel="Escolher arquivos">
          </p-fileUpload>
        </div>
      </p-fieldset>
    </div>
    <div>
      <p-fieldset legend="Exportação">
        <div style="width: 420px;">
          <div>
            <div class="input-date-box">
              <label for="dataInicio">Data inicial</label>
              <p-calendar
                aria-label="Date"
                [(ngModel)]="dataInicio"
                [showIcon]="true"
                inputId="dataInicio"
                dateFormat="dd/mm/yy">
              </p-calendar>
            </div>
            <div class="input-date-box" style="margin-top: 10px;">
              <label for="dataFim">Data final</label>
              <p-calendar
                aria-label="Date"
                [(ngModel)]="dataFim"
                [showIcon]="true"
                inputId="dataFim"
                dateFormat="dd/mm/yy">
              </p-calendar>
            </div>
          </div>
          <div id="radio-button-box">
            <div *ngFor="let check of tipoDocumentoCheck">
              <p-radioButton
                [disabled]="processamentoRelatorio"
                (click)="selectedTipoNota = check"
                (onClick)="setInputDate(check.key)"
                [inputId]="check.key"
                name="tipoDocumentoCheck"
                [value]="check"
                [(ngModel)]="selectedTipoNota">
              </p-radioButton>
              <label style="margin-left: 5px;" [for]="check.key">{{ check.name }}</label>
            </div>
          </div>
        </div>
        <p-button label="Buscar notas" (onClick)="downloadPacoteNotas()"></p-button>
      </p-fieldset>
    </div>
  </div>
</div>
