import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import saveAs from 'file-saver';
import moment from 'moment-timezone';
import { MessageService } from 'primeng/api';
import { DadosMunicipioNfseModel } from 'src/model/dados-municipio-nfse.model';
import { DanfseApiRequest } from 'src/model/danfse-api-request-model';
import { DownloadNotasGeralRequest } from 'src/model/download-notas-geral.request';
import { Empresa } from 'src/model/empresa.model';
import { UploadRequest } from 'src/model/upload-request';
import { AuthService } from "src/services/auth.service";
import { CfeService } from "src/services/cfe.service";
import { CteService } from 'src/services/cte.service';
import { DfeService } from 'src/services/dfe.service';
import { MunicipiosNfseService } from "src/services/municipios-nfse.service";
import { NFSeService } from 'src/services/nfse.service';
import { MainComponent } from '../main.component';
import { ListboxDoubleClickEvent } from 'primeng/listbox';
import { NfseMunicipio } from 'src/model/nfse-municipios';
import { GrupoMunicipios } from 'src/model/grupo-municipios.model';
import { Cliente } from 'src/model/cliente.model';
import { PortalNaciocalService } from 'src/services/portal-nacional-nfse.service';

@Component({
  selector: 'app-importar-exportar-xml',
  templateUrl: './importar-exportar-xml.component.html',
  styleUrl: './importar-exportar-xml.component.scss'
})
export class ImportarExportarXmlComponent implements OnInit {

  @ViewChild('fileUpload') fileUpload!: any;

  @Input()
  get empresa(): any {
    return this._empresa
  }
  set empresa(value: any) {
    this.loadPage = true;
    if (value == undefined) {
      this._empresa = new Empresa();
    } else {
      this._empresa = this.clone(value);
    }

    this.empresaNova = this._empresa.id == undefined || this._empresa.id == null;
    this.exibeCampoAtiva = this.empresaNova || this.isLoggedAdmin;
    this.possuiPendencias = false;
    this._empresaAux = this.clone(this._empresa);
    this.selectedTab = 0;
    this.loadPage = false;

    if (this.empresaNova) {
      this._empresa.ativa = true;
      this._empresa.modulos.nfseModulo.nfseMunicipios = [];
    }

    this.carregaCampoMunicipiosContratados();
  }

  @Output() onClickCancelar = new EventEmitter();
  @Output() empresaChange = new EventEmitter<any>();

  loadPage = true;
  exibeCampoAtiva: boolean = false;
  isLoggedAdmin = false;
  possuiPendencias = false;
  processamentoRelatorio = false;
  empresaNova = false;

  selectedTab: number = 0;

  selectedTipoNota:   any;
  tipoDocumentoCheck: any[] = [
    { name: 'CT-e',  key: 1 },
    { name: 'NF-E',  key: 2 },
    { name: 'NFS-E', key: 3 },
    { name: 'CF-e',  key: 4 },
    { name: 'NFS-e Nacional',  key: 5 },
  ];

  clienteLogado:             any;
  subscriptionEmpresaChange: any;
  selectedRows:              any[] = [];

  _empresaAux: Empresa = new Empresa();
  _empresa:    Empresa = new Empresa();
  selectedEmpresa?:          Empresa;

  dataInicio: Date = new Date();
  dataFim:    Date = new Date();

  tipo: { name: string, key: number }[] = [
    { name: 'NF-e', key: 1 },
    { name: 'CT-e', key: 2 },
    { name: 'NFS-e', key: 3 },
    { name: 'CF-e', key: 4 },
    { name: 'NFS-e Nacional',  key: 5 }
  ];

  grupoMunicipios: GrupoMunicipios[] = [];
  userLogged!: Cliente;
  municipiosContratados: DadosMunicipioNfseModel[] = [];

  selectedTipo: { name: string, key: number } = { name: 'Nenhum', key: 99 };
  xmlUploadedFiles?: File[];
  todosMunicipios: DadosMunicipioNfseModel[] = [];
  municipiosSelecionados: DadosMunicipioNfseModel[] = [];
  selectedMunicipio?: DadosMunicipioNfseModel;

  _chaveAcesso!: String;

  constructor(
    private authService: AuthService,
    private municipiosNfseService: MunicipiosNfseService,
    private main: MainComponent,
    private nfseService: NFSeService,
    private cteService: CteService,
    private nfeService: DfeService,
    private cfeService: CfeService,
    private portalService: PortalNaciocalService,
    private messageService: MessageService
  ) {
    this.clienteLogado = authService.getUsuario();
  }

  ngOnInit(): void {

    this.municipiosNfseService.getMunicipios().then(muns => {
      this.todosMunicipios = muns;

      if (this.main.selectedEmpresa) {
        this.selectedEmpresa = this.main.selectedEmpresa;
      }

      this.subscriptionEmpresaChange = this.main.empresaChange.subscribe((empresa: Empresa) => {
        this.selectedEmpresa = empresa;
        this.carregaCampoMunicipiosContratados();
      });
    })

    this.userLogged = this.authService.getUsuario()!;

    this.isLoggedAdmin = this.authService.getUsuario()?.login.toUpperCase() === 'ADMIN';

    this.municipiosNfseService.getMunicipios().then(muns => {
      this.municipiosContratados = muns;
      this.carregaCampoMunicipiosContratados();
    });

    this.municipiosNfseService.getGrupoMunicipios().then(result => {
      this.grupoMunicipios = result;
    });

    if (this.main.selectedEmpresa) {
      this.selectedEmpresa = this.main.selectedEmpresa;
    }

    this.selectedTipoNota = this.tipoDocumentoCheck[0];

    this.setInputDate(parseInt(this.selectedTipoNota.key) - 1);

    this.subscriptionEmpresaChange = this.main.empresaChange.subscribe((empresa: Empresa) => {
      this.selectedEmpresa = empresa;
      this.carregaCampoMunicipiosContratados();
    });

    if (this.main.selectedEmpresa) {
      this.selectedEmpresa = this.main.selectedEmpresa;
    }

    this.selectedEmpresa = this.main.getSelectedEmpresa();

    this.carregaCampoMunicipiosContratados();


  }

  ngOnChanges(_changes: SimpleChanges) {
    this.municipiosSelecionados = [];
    if (this._empresa.modulos.nfseModulo.nfseMunicipios && this._empresa.modulos.nfseModulo.nfseMunicipios.length > 0) {
      this._empresa.modulos.nfseModulo.nfseMunicipios.forEach(async mun => {
        if (mun.codigoMun) {
          await this.municipiosNfseService.getMunicipioPeloCodigo(mun.codigoMun).then(result => {
            this.municipiosSelecionados.push(result);
            this.verificarPendencia(result);
          });
        }
      });
    }
    setTimeout(() => {
      this.limparErros();
      if (this.fileUpload) {
        this.fileUpload.clear();
      }
    });

    this.carregaCampoMunicipiosContratados();

  }

  async onDoubleClickSelecaoMunicipio(event: ListboxDoubleClickEvent) {
    const codMun = event.option.value;
    if (!this.municipiosSelecionados.find(t => t.codigo == codMun)) {
      await this.municipiosNfseService.getMunicipioPeloCodigo(codMun).then(result => {
        this.municipiosSelecionados.push(result);
        let municipioNfse: NfseMunicipio = new NfseMunicipio();
        municipioNfse.codigoMun = codMun;
        this._empresa.modulos.nfseModulo.nfseMunicipios.push(municipioNfse);
        this.verificarPendencias();
      });
    }
  }

  private verificarPendencias() {
    this.possuiPendencias = false;
    this.municipiosSelecionados.forEach(munSel => {
      if (munSel) {
        this.verificarPendencia(munSel);
      }
    });
  }

  private verificarPendencia(munSel: DadosMunicipioNfseModel) {
    const munEmp = this._empresa.modulos.nfseModulo.nfseMunicipios.find(t => t.codigoMun == munSel.codigo);
    var pendenciaLoginSite = false;
    var pendenciaInscricaoMunicipal = false;
    if (munSel?.requerLoginSite) {
      pendenciaLoginSite = !(munEmp?.login && munEmp.senha);
    }
    if (munSel?.requerInscricaoMunicipal) {
      pendenciaInscricaoMunicipal = !munEmp?.inscricaoMunicipal;
    }
    munSel.pendencia = pendenciaLoginSite || pendenciaInscricaoMunicipal;
    if (!this.possuiPendencias) {
      this.possuiPendencias = munSel.pendencia;
    }
  }

  setInputDate(day: number | string): void {
    if (this.selectedTipoNota && this.selectedTipoNota.key) {
      day = (parseInt(this.selectedTipoNota.key) - 1).toString();
    } else {
      console.error("selectedTipoNota is undefined or has no key");
    }
  }

  //Download de notas por data
  downloadPacoteNotas() {
    console.log('Iniciando downloadPacoteNotas');
    let request = new DownloadNotasGeralRequest();
    if (this.verificaDatas(request) && this.verificaEmpresa(request)) {
      if (this.selectedTipoNota.key == 1) {
        this.downloadPacoteNotasCTe(request);
      } else if (this.selectedTipoNota.key == 2) {
        this.downloadPacoteNotasNFe(request);
      } else if (this.selectedTipoNota.key == 3) {
        this.downloadPacoteNotasNFSe(request);
      } else if (this.selectedTipoNota.key == 4) {
        this.downloadPacoteNotasCFE(request);
      } else { this.downloadPacoteNotasPortalNacional(request);
      }
    }
  }

  private verificaDatas(request: DownloadNotasGeralRequest): boolean {
    if (!this.dataInicio || !this.dataFim) {
      this.showMensagemAlerta("Alerta", "Datas inseridas incorretamente");
      return false;
    } else if (this.dataInicio > this.dataFim) {
      this.showMensagemAlerta("Alerta", "Data inicial maior que data final");
      return false;
    } else {
      const dataInicioUtc = moment(this.dataInicio).tz('UTC').startOf('day').toDate();
      const dataFimUtc = moment(this.dataFim).tz('UTC').endOf('day').toDate();

      request.dataInicio = dataInicioUtc;
      request.dataFinal = dataFimUtc;
      return true;
    }
  }

  private verificaEmpresa(request: DownloadNotasGeralRequest): boolean {
    if (!this.selectedEmpresa?.id) {
      this.showMensagemAlerta("", "Nenhuma empresa foi selecionada");
    } else {
      request.idEmpresa = this.selectedEmpresa?.id?.toString();
      return true
    }
    return false;
  }

  private downloadPacoteNotasNFe(request: DownloadNotasGeralRequest) {
    this.nfeService.downloadPacoteNotasFiscais(request).subscribe({
      next: blob => {
        if (!blob.body) {
          this.showMensagemInfo("Aviso", "Nenhum arquivo NFE encontrado no período selecionado.");
        } else {
          this.triggerDownload(blob);
        }
      },
      error: e => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private downloadPacoteNotasCTe(request: DownloadNotasGeralRequest) {
    this.cteService.downloadPacoteConhecimentoTransporteEletronico(request).subscribe({
      next: blob => {
        if (!blob.body) {
          this.showMensagemInfo("Aviso", "Nenhum arquivo CTE encontrado no período selecionado.");
        } else {
          this.triggerDownload(blob);
        }
      },
      error: e => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private downloadPacoteNotasNFSe(request: DownloadNotasGeralRequest) {
    this.nfseService.downloadPacoteNotasFiscaisEletronicas(request).subscribe({
      next: blob => {
        if (!blob.body) {
          this.showMensagemInfo("Aviso", "Nenhum arquivo NFSE encontrado no período selecionado.");
        } else {
          this.triggerDownload(blob);
        }
      },
      error: e => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private downloadPacoteNotasCFE(request: DownloadNotasGeralRequest) {
    this.cfeService.downloadPacoteCupomFiscal(request).subscribe({
      next: blob => {
        if (!blob.body) {
          this.showMensagemInfo("Aviso", "Nenhum arquivo CF-E encontrado no período selecionado.");
        } else {
          this.triggerDownload(blob);
        }
      },
      error: e => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private downloadPacoteNotasPortalNacional(request: DownloadNotasGeralRequest) {
    this.portalService.downloadPacotePortalNacional(request).subscribe({
      next: blob => {
        if (!blob.body) {
          this.showMensagemInfo("Aviso", "Nenhuma nota encontrado no período selecionado.");
        } else {
          this.triggerDownload(blob);
        }
      },
      error: e => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  //Upload de XML's
  onUploadXml(event: any) {
    if (event.files[0]) {
      this.xmlUploadedFiles = event.files;
    }

    let request = new UploadRequest;

    request.idEmpresa = this.selectedEmpresa?.id;
    request.arquivos = this.xmlUploadedFiles!;
    request.codigoMun = this.selectedMunicipio?.codigo;
    request.clienteLogin = this.userLogged?.login;

    console.log(this.selectedEmpresa?.id);
    console.log(request.clienteLogin);

    switch (this.selectedTipo.key) {
      case 1:
        this.nfeUpload(request);
        break;
      case 2:
        this.cteUpload(request);
        break;
      case 3:
        if (this.selectedMunicipio) {
          this.nfseUpload(request);
        } else {
          this.showMensagemAlerta(" ", "Selecione um município");
        }
        break;
      case 4:
        this.cfeUpload(request);
        break;
      case 5:
        this.portalUpload(request);
        break;
      default:
        this.showMensagemAlerta(" ", "Selecione o tipo");
        break;
    }
  }

  private nfeUpload(request: UploadRequest) {
    this.nfeService.sendUploadedXml(request).subscribe({
      next: () => {
        this.showMensagemInfo("Requisição enviada", "Aguardar a notificação da conclusão do processamento.");
      },
      error: (e) => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private cteUpload(request: UploadRequest) {
    this.cteService.sendUploadedXml(request).subscribe({
      next: () => {
        this.showMensagemInfo("Requisição enviada", "Aguardar a notificação da conclusão do processamento.");
      },
      error: (e) => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private nfseUpload(request: UploadRequest) {
    this.nfseService.sendUploadedXml(request).subscribe({
      next: () => {
        this.showMensagemInfo("Requisição enviada", "Aguardar a notificação da conclusão do processamento.");
      },
      error: (e) => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private cfeUpload(request: UploadRequest) {
    this.cfeService.sendUploadedXml(request).subscribe({
      next: () => {
        this.showMensagemInfo("Requisição enviada", "Aguardar a notificação da conclusão do processamento.");
      },
      error: (e) => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private portalUpload(request: UploadRequest) {
    this.portalService.sendUploadedXml(request).subscribe({
      next: () => {
        this.showMensagemInfo("Requisição enviada", "Aguardar a notificação da conclusão do processamento.");
      },
      error: (e) => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  downloadDanfseAPI() {
    let request = new DanfseApiRequest;
    request.chaveAcesso = this._chaveAcesso;
    request.cnpjCpfEmpresa = this._empresa.id;

    console.log(request.chaveAcesso)
    console.log(request.cnpjCpfEmpresa)

    this.nfeService.consultaDanfseAPI(request).subscribe({
      next: blob => {
        if (!blob.body) {
          this.showMensagemInfo("Aviso", "Nenhuma DANFSe encontrada com a chave de acesso.");
        } else {
          this.triggerDownload(blob)
          this.showMensagemSuccess("Sucesso", "Download concluído com sucesso.");
        }
      },
      error: e => {
        this.showMensagemErro("Erro", "Verificar a chave de acesso informada.");
      }
    })
  }

  private triggerDownload(blob: HttpResponse<Blob>) {
    let filename = blob.headers.get('Content-Disposition')?.split('filename=')[1].split(';')[0];
    filename = filename ? filename.trim() : `documentos_fiscais_${moment().format('YYYYMMDD')}.zip`;
    saveAs(blob.body!, filename);
  }

  private showMensagemAlerta(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastTopCenter800px',
      severity: 'warn',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  private showMensagemInfo(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastTopCenter800px',
      severity: 'info',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 5000
    });
  }

  private showMensagemErro(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastTopCenter800px',
      severity: 'error',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  private showMensagemSuccess(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastTopCenter800px',
      severity: 'success',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 5000
    });
  }

  onChangeTipo() {
    this.carregaCampoMunicipiosContratados();
  }

  private carregaCampoMunicipiosContratados() {
    this.municipiosContratados = [];
    if (this.selectedEmpresa && this.selectedTipo && this.selectedTipo.key === 3) {
      this.selectedEmpresa.modulos?.nfseModulo.nfseMunicipios?.forEach(dadosMun => {
        const mun = this.todosMunicipios.find(mun => mun.codigo == dadosMun.codigoMun);
        if (mun) {
          this.municipiosContratados.push(mun);
        }
      });
      setTimeout(() => {
        this.selectedMunicipio = this.municipiosContratados[0];
      });
    }

  }

  private limparErros() {
    $('#campoCnpj-erro').hide();
    $('#campoCnpj').removeClass('ng-invalid');
    $('#campoEmail-erro').hide();
    $('#campoEmail').removeClass('ng-invalid');
  }

  close() {
    this.onClickCancelar.emit();
  }

  private clone(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

}
