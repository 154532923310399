<div *ngIf="isEnableNfsePage; else notEnabledNfse">
<p-contextMenu #cm [model]="itemsContextMenu" (onHide)="removeHighlightContextmenu()"></p-contextMenu>

  <div class="container">
    <div class="container-width">
      <!-- TITULO -->
      <div class="d-flex flex-row align-items-baseline" style="margin-top: -10px;">
        <div class="flex-grow-1">
          <div class="d-flex flex-row justify-content-left align-content-center">
            <div style="width: 200px;">
              <h4 style="line-height: 2">
                NFS-e
              </h4>
            </div>
          </div>
        </div>
        <div style="margin-top: -10px;">
          <button pButton class="p-button-rounded p-button-text me-5 ms-4" pTooltip="Recarrega dados da tabela" [showDelay]="500" (click)="refresh()">
            <i class="pi pi-refresh"></i>
          </button>
        </div>
      </div>

      <!-- Botões de ações -->
      <div class="d-flex align-items-center me-2" style="margin-top: -10px;">
        <div class="field me-4">
          <label class="block">&nbsp;</label>
          <p-dropdown
            [options]="municipiosContratados"
            [autoDisplayFirst]="true"
            [editable]="false"
            [(ngModel)]="selectedMunicipio"
            [ngModelOptions]="{standalone: true}"
            (onChange)="onChangeMunicipio($event)"
            optionLabel="municipio"
            placeholder="Selecione o município"
            [style]="{'width' : '280px'}"
            [disabled]="todosMunicipios.length == 0">
          </p-dropdown>
        </div>
        <div style="margin-top: 10px;">
          <p-selectButton
            [options]="contextOptions"
            [(ngModel)]="contextSel"
            optionLabel="icon"
            (onChange)="onChangeContextOptions($event)">
            <ng-template let-item pTemplate>
              <i [class]="item.icon"></i>
              <div class="ms-2 mt-1">{{item.label}}</div>
            </ng-template>
          </p-selectButton>
        </div>
        <div class="flex-grow-1"></div>
        <div class="mt-2">
          <div class="d-flex">
            <div>
              <button pButton type="button" [disabled]="selectedEmpresa == undefined" icon="pi pi-cloud-download" label="Busca novas notas"
                class="p-button-outlined botao-custom" (click)="openModalBuscarNovasNotas()">
              </button>
              &nbsp;&nbsp;
              <button pButton type="button" class="p-button-rounded p-button-secondary" pTooltip="Download das notas selecionadas" tooltipPosition="top"
                icon="pi pi-download" (click)="downloadNFSeSelecionadas()" [disabled]="selectedRows.length == 0">
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        <!-- Tabela com dados -->
        <p-table #dt
          class="table-nfse"
          [value]="nfseResponse.content"
          [(selection)]="selectedRows"
          dataKey="id"
          [showCurrentPageReport]="true"
          [rowsPerPageOptions]="pageSizes"
          [rows]="pageSizes[0]"
          [loading]="loading"
          [paginator]="true"
          currentPageReportTemplate="{first} {{'até'}} {last} {{'de'}} {{nfseResponse.totalElements}} {{'entradas'}}"
          [scrollable]="true"
          scrollHeight="calc(100% - 200px)"
          [contextMenu]="cm"
          [(contextMenuSelection)]="selectedNFSeContextMenu">
          <!-- HEADER -->
          <ng-template pTemplate="header">
            <tr>
              <th style="min-width:45px; padding-left: 16px">
                <div style="padding-left: 0px; padding-right: 0px;">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </div>
              </th>
              <th style="min-width:92px" pSortableColumn="status">
                Status
                <p-sortIcon field="status"></p-sortIcon>
              </th>
              <th style="min-width:105px" pSortableColumn="numero">
                Número
                <p-sortIcon field="numero"></p-sortIcon>
              </th>
              <th style="min-width:130px" pSortableColumn="dataEmissao">
                Data da emissão
                <p-sortIcon field="dataEmissao"></p-sortIcon>
              </th>
              <th style="min-width:auto" pSortableColumn="nomePrestador">
                <span *ngIf="contextSel?.value! == 'PRESTADA'">Tomador</span>
                <span *ngIf="contextSel?.value! == 'TOMADA'">Prestador</span>
                <p-sortIcon field="nomePrestador"></p-sortIcon>
              </th>
              <th style="min-width:125px" pSortableColumn="valor">
                Valor
                <p-sortIcon field="valor"></p-sortIcon>
              </th>
              <th style="min-width:125px" pSortableColumn="origem">
                Origem
                <p-sortIcon field="origem"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <!-- BODY -->
          <ng-template pTemplate="body" let-nfse>
            <tr [pContextMenuRow]="nfse" class="table-row-body">
              <td>
                <p-tableCheckbox [value]="nfse" class="ml-1"></p-tableCheckbox>
              </td>
              <td style="text-align:center; padding-right: 45px !important;">{{nfse.status}}</td>
              <td>{{nfse.numero}}</td>
              <td>
                {{nfse.dataEmissao | date:'dd/MM/yyyy'}}
                <span class="ms-2" style="color: rgb(165, 165, 165); font-size: 12px;">
                  {{nfse.dataEmissao | date:'HH:mm:ss'}}
                </span>
              </td>
              <td *ngIf="contextSel?.value! == 'TOMADA'">{{ (nfse.nomePrestador.length>6)? (nfse.nomePrestador | slice:0:29)+'...':(nfse.nomePrestador)}}</td>
              <td *ngIf="contextSel?.value! == 'PRESTADA'">{{ (nfse.nomeTomador.length>6)? (nfse.nomeTomador | slice:0:29)+'...':(nfse.nomeTomador)}}</td>
              <td>{{nfse.valor | currency : 'BRL'}}</td>
              <td>{{nfse.nfSeOrigem }}</td>
            </tr>
          </ng-template>
        </p-table>
        <div *ngIf="nfseResponse.totalElements == 0" class="mt-3 w-100 d-flex justify-content-center">
          <div>Sem dados</div>
        </div>
      </div>
      <div *ngIf="nfseResponse.totalElements > 0">
        <b>Selecionadas:</b> {{selectedRows.length}}
      </div>
    </div>
  </div>
</div>

<ng-template #notEnabledNfse>
  <div class="centered-container">
    <div class="centered-content">
      <img src="assets/images/danger.png" alt="Danger">
      <p>Empresa não cadastrada no módulo NFS-e</p>
    </div>
  </div>
</ng-template>

<!-- Modal - seleção de datas para consulta das notas fiscais -->
<p-dialog
  [header]="summaryModalSelecaoDatas"
  [modal]="true"
  [(visible)]="showModalSelecaoDatas"
  [breakpoints]="{'960px': '75vw'}"
  [style]="{width: '30vw', height: '270px'}"
  [draggable]="false"
  [resizable]="false">

  <div class="ml-5 mr-5">
    <div class="mb-5">
      <p>Só é possível buscar num intervalo de 7 dias e que as datas estejam dentro do mesmo mês.</p>
    </div>
    <div class="d-flex justify-content-center mt-5 mb-4">
      <p-inputNumber [style]="{display: 'none'}"></p-inputNumber>
      <div class="bd-highlight margin-r-3">
        <div class="input-label input-date">
          <label for="dataDe">Data de:</label>
          <p-calendar
            [(ngModel)]="nfseConsultaWs.dataInicio"
            [style]="{width: '126px'}"
            [showButtonBar]="true"
            [showIcon]="true"
            [iconDisplay]="'input'"
            dateFormat="dd/mm/yy"
            appendTo="body"
            [minDate]="minDateDe"
            [maxDate]="maxDateDe"
            (onSelect)="isBuscarDisabled()">
          </p-calendar>
        </div>
      </div>
      <div class="bd-highlight margin-r-3">
        <div class="input-label input-date">
          <label for="dataAte">Data até:</label>
          <p-calendar
            [(ngModel)]="nfseConsultaWs.dataFim"
            [style]="{width: '126px'}"
            [showButtonBar]="true"
            [showIcon]="true"
            [iconDisplay]="'input'"
            dateFormat="dd/mm/yy"
            appendTo="body"
            [minDate]="minDateAte"
            [maxDate]="maxDateAte"
            (onSelect)="isBuscarDisabled()">
          </p-calendar>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-4" style="margin-right: 1.9rem;">
      <button
        pButton
        [style]="{width: '272px'}"
        type="button"
        label="Buscar"
        class="botao-custom"
        (click)="buscaNovasNotas()"
        [disabled]="isBuscarDisabled()">
      </button>
    </div>
  </div>
</p-dialog>

<!-- Modal DANFSE -->
<p-dialog
  header="DANFSE"
  [modal]="false"
  [(visible)]="showModalDanfse"
  [breakpoints]="{'960px': '75vw'}"
  [style]="{width: '85vw', height: '90vw'}"
  [draggable]="false"
  [resizable]="false">
  <div class="iframe-danfe" [ngClass]="{'h-100' : !showProgressSpinModalDanfse, 'd-none': showProgressSpinModalDanfse}">
    <iframe id="pdfViewer" [src]="base64Pdf" width="100%" height="100%"></iframe>
  </div>
  <div class="d-flex align-items-center justify-content-center h-100" *ngIf="showProgressSpinModalDanfse">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-dialog>
